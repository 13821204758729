<template>
  <v-dialog
    v-model="showup"
    max-width="500px"
    :persistent="true"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Record new Course</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="title"
                label="Title"
                name="Title"
                v-validate="'required'"
                outlined
                dense
              ></v-text-field>
              <span style="color: red;"
                v-show="errors.has('Title')" v-if="valid">{{ errors.first('Title') }}</span>
            </v-col>
            <v-col cols="12">
              <v-text-field
                name="Capacity"
                label="Course code"
                v-model="code"
                type="text"
                v-validate="'required'"
                outlined
                dense
              >
              </v-text-field>
              <span style="color: red;"
                v-show="errors.has('Capacity')" v-if="valid">{{ errors.first('Capacity') }}</span>
            </v-col>
            <v-col cols="12">
              <v-text-field
                name="Credits"
                label="Course credits"
                v-model="credits"
                type="text"
                v-validate="'required|numeric'"
                outlined
                dense
              >
              </v-text-field>
              <span style="color: red;"
                v-show="errors.has('Credits')" v-if="valid">{{ errors.first('Credits') }}</span>
            </v-col>
            <v-col cols="12">
              <v-text-field
                name="max points"
                label="Course points"
                v-model="points"
                type="text"
                v-validate="'required|numeric'"
                outlined
                dense
              >
              </v-text-field>
              <span style="color: red;"
                v-show="errors.has('max points')" v-if="valid">{{ errors.first('max points') }}</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="offModal"
          style="text-transform: none"
          outlined
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="validate"
          style="text-transform: none"
          outlined
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'add-class',
  props: {
    showup: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      logger: [],
      header: '',
      title: '',
      code: '',
      points: '',
      credits: '',
      valid: true,
    }
  },
  methods: {
    offModal: function () {
      this.title = ''
      this.credits = ''
      this.code = ''
      this.points = ''
      this.valid = false
      this.$emit('closeModal', false)
    },
    async validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.save()
        } else {
          // form have errors
        }
      })
    },
    async save () {
      if (localStorage.getItem('logged_user')) {
        this.logger = JSON.parse(localStorage.getItem('logged_user'))
      }
      this.header = this.logger.accessToken
      const data = {
        title: this.title,
        code: this.code,
        credits: this.credits,
        points: this.points,
      }
      try {
        const res = await this.axios({
          url: 'manipulate_course',
          method: 'post',
          data: data,
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          this.$emit('getResponse', res.data)
          this.offModal()
        } else {
          alert('Data not saved')
        }
      } catch (e) {
        if (e.response && e.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else if (e.response && e.response.status === 400) {
          alert(e.response.data.messages)
        } else {
          console.log(e)
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
